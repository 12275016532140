import { createSlice } from "@reduxjs/toolkit"

export const transactionSlice = createSlice({
  name: "transaction",
  initialState: {
    transactionList: [],
    exportList: [],
    transactionDetail: {},
    isFreshData: false,
    totalEntry: 0,
    filterTerm: ''
  },
  reducers: {
    setTransactionList: (state, action) => {
      state.transactionList = action.payload
    },
    setExportList: (state, action) => {
      state.exportList = action.payload
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setSingleData: (state, action) => {
      state.transactionDetail = action.payload
    }
  }
})

export const {
  setTransactionList,
  setExportList,
  setFilterTerm,
  setSingleData,
  setTotalEntry,
  setFilteredList
} = transactionSlice.actions

export const selectTransactionList = (state) => state.transaction.transactionList
export const selectExportList = (state) => state.transaction.exportList
export const selectFilterTerm = (state) => state.transaction.filterTerm
export const selectTotalEntry = (state) => state.transaction.totalEntry
export const selectTransactionDetail = (state) => state.transaction.transactionDetail

export default transactionSlice.reducer
