import { createSlice } from "@reduxjs/toolkit"

export const voucherSlice = createSlice({
    name: "voucher",
    initialState: {
        voucherList: [],
        voucherDetail: {},
        isFreshData: false,
        totalEntry: 0,
    },
    reducers: {
        setVoucherList: (state, action) => {
            state.voucherList = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.voucherDetail = action.payload
        }
    }
})

export const {
    setVoucherList,
    setSingleData,
    setTotalEntry,
} = voucherSlice.actions

export const selectVoucherList = (state) => state.voucher.voucherList
export const selectTotalEntry = (state) => state.voucher.totalEntry
export const selectDetail = (state) => state.voucher.voucherDetail

export default voucherSlice.reducer
