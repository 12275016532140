import { createSlice } from "@reduxjs/toolkit"

export const sizeSlice = createSlice({
  name: "size",
  initialState: {
    sizeList: [],
    exportList: [],
    sizeDetail: {},
    isFreshData: false,
    totalEntry: 0,
    filterTerm: ''
  },
  reducers: {
    setSizeList: (state, action) => {
      state.sizeList = action.payload
    },
    setExportList: (state, action) => {
      state.exportList = action.payload
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload
    },
    setFreshList: (state, action) => {
      state.isFreshData = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setSingleData: (state, action) => {
      state.sizeDetail = action.payload
    }
  }
})

export const {
  setSizeList,
  setExportList,
  setFilterTerm,
  setSingleData,
  setTotalEntry,
  setFilteredList,
  setFreshList
} = sizeSlice.actions

export const selectSizeList = (state) => state.size.sizeList
export const selectExportList = (state) => state.size.exportList
export const selectFilterTerm = (state) => state.size.filterTerm
export const selectTotalEntry = (state) => state.size.totalEntry
export const selectSizeDetail = (state) => state.size.sizeDetail
export const selectFreshData = (state) => state.size.isFreshData

export default sizeSlice.reducer
