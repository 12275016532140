import { createSlice } from "@reduxjs/toolkit"

export const userSlice = createSlice({
  name: "user",
  initialState: {
    userList: [],
    exportList: [],
    userDetail: {},
    totalEntry: 0,
    filterTerm: ''
  },
  reducers: {
    setUserList: (state, action) => {
      state.userList = action.payload
    },
    setExportList: (state, action) => {
      state.exportList = action.payload
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setSingleData: (state, action) => {
      state.userDetail = action.payload
    }

  }
})

export const {
  setUserList,
  setExportList,
  setFilterTerm,
  setSingleData,
  setTotalEntry,
  setFilteredList
} = userSlice.actions

export const selectUserList = (state) => state.users.userList
export const selectExportList = (state) => state.users.exportList
export const selectFilterTerm = (state) => state.users.filterTerm
export const selectTotalEntry = (state) => state.users.totalEntry
export const selectUserDetail = (state) => state.users.userDetail

export default userSlice.reducer
