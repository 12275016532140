import { createSlice } from "@reduxjs/toolkit"

export const categorySlice = createSlice({
    name: "category",
    initialState: {
        categoryList: [],
        exportList: [],
        categoryDetail: {},
        isFreshData: false,
        totalEntry: 0,
        filterTerm: ''
    },
    reducers: {
        setCategoryList: (state, action) => {
            state.categoryList = action.payload
        },
        setFreshList: (state, action) => {
            state.isFreshData = action.payload
        },
        setExportList: (state, action) => {
            state.exportList = action.payload
        },
        setFilterTerm: (state, action) => {
            state.filterTerm = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.categoryDetail = action.payload
        }
    }
})

export const {
    setCategoryList,
    setExportList,
    setFreshList,
    setFilterTerm,
    setSingleData,
    setTotalEntry,
    setFilteredList
} = categorySlice.actions

export const selectCategoryList = (state) => state.category.categoryList
export const selectExportList = (state) => state.category.exportList
export const selectFilterTerm = (state) => state.category.filterTerm
export const selectFreshData = (state) => state.category.isFreshData
export const selectTotalEntry = (state) => state.category.totalEntry
export const selectDetail = (state) => state.category.categoryDetail

export default categorySlice.reducer
