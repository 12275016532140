import { createSlice } from "@reduxjs/toolkit"

export const newsSlice = createSlice({
  name: "news",
  initialState: {
    newsList: [],
    newsDetail: {},
    isFreshData: false,
    totalEntry: 0,
  },

  reducers: {
    setNewsList: (state, action) => {
      state.newsList = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setSingleData: (state, action) => {
      state.newsDetail = action.payload
    },
    setFreshList: (state, action) => {
      state.isFreshData = action.payload
    }
  }
})

export const {
  setNewsList,
  setSingleData,
  setTotalEntry,
  setFreshList
} = newsSlice.actions

export const selectNewsList = (state) => state.news.newsList
export const selectTotalEntry = (state) => state.news.totalEntry
export const selectNewsDetail = (state) => state.news.newsDetail
export const selectFreshData = (state) => state.news.isFreshData

export default newsSlice.reducer
